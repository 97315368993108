.daily_entry_container {
  width: 80%;
  max-width: 500px;
  margin: auto;
  padding: 15px;
  background-color: white;
  border-radius: 10px;
  margin-top: 40px;
}

.someone_shared_container {
  width: 80%;
  max-width: 550px;
  margin: auto;
  padding: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.download_text_prompt {
  color: white;
  font-size: 14px;
}

.share_link_download_container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.someone_shared_text {
  color: white;
  margin-left: 10px;
  font-weight: bold;
}

.someone_shared_icon {
  width: 50px;
  height: 50px;
}

.daily_entry_header {
  color: #009fb7;
  font-weight: bold;
  font-size: 20px;
}

.daily_entry_title {
  color: #009fb7;
  font-weight: bold;
  font-size: 18px;
  margin-top: 20px;
}

.daily_timeline_entry_event_name {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0;
  padding: 0;
}

.daily_timeline_entry_event_description {
  color: rgba(0, 0, 0, 0.7);
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 14px;
}

.timeline_event_name_text {
  font-weight: bold;
  color: #009fb7;
  margin-top: 0px;
}

.timeline_event_marker {
  width: 8px;
  height: 12px;
  border: 5px solid #009fb7; /* Border color */
  border-radius: 50%; /* Make it round */
  position: relative; /* Position inner circle relative to this container */
  flex-grow: 1;
}

.marker_line_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.marker_line_container_start {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.timeline_event_line {
  border: 3px solid #009fb7;
  width: 0px;
  flex-grow: 1;
  height: 100%;
}

.timeline_event_line_start {
  border: 3px solid #009fb7;
  width: 0px;
  flex-grow: 1;
  height: 15px;
  margin: auto;
}

.timeline_event_container {
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.title_description_box {
  margin-bottom: 10px;
  margin-left: 10px;
}

.start_end_marker {
  width: 18px;
  height: 18px;
  background-color: #009fb7;
  border-radius: 50%;
  position: relative;
}

.timeline_container {
  padding: 20px;
  padding-top: 10px;
}

.timeline_header {
  color: #009fb7;
  font-size: 20px;
  margin-bottom: 12px;
  margin-top: 10px;
}

.media_carousel_media {
  width: 350px;
  border-radius: 10px;
  margin-left: 0px;
  margin-right: 0px;
}

.media_carousel_media_video {
  height: 467px;
  border-radius: 10px;
  margin-left: 0px;
  margin-right: 0px;
}

.media_carousel_media_hidden {
  display: none;
  width: 0px;
}

.media_item_container {
  border-radius: 10px;
  width: 350px;
  height: 467px;
  margin-top: 10px;
  margin-left: 0px;
  margin-right: 0px;
  flex: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.download_logo {
  width: 130px;
}

.video_container {
  width: 350px;
  height: 467px;
  margin-left: 0px;
  margin-right: 0px;
  flex: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.media_carousel_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.media_item_container_centerer {
  flex: 1;
  min-height: 467px;
  max-width: 350px;
  position: relative;
}

.daily_entry_tag_text {
  font-weight: bold;
  font-size: 12px;
}

.daily_entry_tag {
  padding: 3px;
  border-radius: 5px;
  box-shadow: 1px 2px rgba(0, 0, 0, 0.01);
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 5px;
  padding-left: 5px;
  margin-left: 7px;
  margin-top: 10px;
}

.mentioned_people_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 10px;
}

.locations_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 0px;
}

.memorable_moments_item_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 0px;
}

.media_loader_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 0px;
}

.memorable_moments_header {
  margin-top: 30px;
  font-weight: bold;
  color: rgba(79, 160, 149, 1);
  margin-bottom: 5px;
  position: relative;
  left: 8px;
}

.chevron_swiper {
  color: rgba(0, 159, 183, 0.5);
  margin-left: 15px;
  margin-right: 15px;
}

.image_loader_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 350px;
  height: 467px;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  align-items: center;
}

@media (max-width: 768px) {
  .daily_entry_header {
    font-size: 18px;
  }

  .chevron_swiper {
    display: none;
  }

  .timeline_header {
    font-size: 17px;
  }

  .timeline_event_name_text {
    font-size: 13px;
  }

  .daily_timeline_entry_event_description {
    font-size: 12px;
  }

  .daily_entry_title {
    font-size: 14px;
    margin-top: 10px;
  }

  .timeline_container {
    padding: 10px;
  }

  .daily_entry_tag_text {
    font-size: 11px;
  }

  .daily_entry_tag {
    padding: 5px;
  }

  .timeline_event_name_text {
    font-weight: bold;
    color: #009fb7;
    margin-top: 0px;
    margin-bottom: 2px;
  }

  .media_item_container {
    margin-top: 2px;
    width: 300px;
    height: 400px;
  }

  .media_item_container_centerer {
    flex: 1;
    min-height: 400px;
    max-width: 300px;
    position: relative;
  }

  .media_carousel_media {
    width: 300px;
  }

  .media_carousel_media_video {
    height: 400px;
  }

  .image_loader_container {
    min-width: 300px;
    min-height: 400px;
  }

  .video_container {
    height: 400px;
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.1);
  }
}
