body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.footer {
  display: flex;
  flex-direction: row;
  width: "100%";
  justify-content: center;
  margin-bottom: 50px;
  align-items: center;
}

.text {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.pageContainer {
  height: 100vh; /* Set the container height to the full viewport height */
  display: flex;
  width: 100%;
  flex-direction: column;
  /* Add any other styling for your content here */
}

.privacy_policy_container {
  width: "90%";
  margin: auto;
  max-width: 600px;
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 10px;
  margin-top: 30px;
  padding-left: 20px;
  padding-right: 20px;
}

@media (max-width: 768px) {
  .privacy_policy_container {
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    max-width: 80%;
  }
}

.privacy_policy_text {
  color: rgba(0, 0, 0, 0.7);
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.privacy_policy_header {
  color: black;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
