.pageContainer {
  height: 100vh; /* Set the container height to the full viewport height */
  display: flex;
  width: 100%;
  flex-direction: column;
  /* Add any other styling for your content here */
}

.solid_colour_container {
  background: #009fb7;
}

.top_section {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.faded_container {
  background: linear-gradient(to bottom, #009fb7, #66a5ad);
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.feature_slide {
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.7s ease-in-out;
}

.feature_description_container_mobile {
  margin-top: 20px;
}

.visible_feature {
  transform: translateX(0%);
}

.invisible_feature {
  transform: translateX(-70%);
  opacity: 0;
  display: "none";
}

.feature_mobile_image_invisible {
  opacity: 0;
  max-width: 0px;
}

.feature_mobile_image_visible {
  opacity: 1;
}

.faded_down_container {
  background: linear-gradient(to top, #009fb7, #66a5ad);
  height: 150;
}

.carousel_container {
  width: "100%";
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 720px;
  width: "100%";
  position: relative;
}

@media screen and (max-width: 899px) {
  .faded_down_container {
    height: 100px;
  }
}

.main_description {
  margin-right: 50px;
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100vw;
}

@media screen and (max-width: 1099px) {
  .main_description {
    margin-right: 20px; /* Adjust margin-right for screen width less than 1100px */
  }
}

.lifememologo {
  width: 250px;
  margin-top: 25px;
  margin-left: 30px;
}

.sub_green_header {
  color: rgb(134, 234, 183);
  font-size: 22px;
  margin-top: 30px;
  margin-bottom: 20px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  max-width: 400px;
  font-weight: bold;
}

.sub_green_header_mobile {
  color: rgb(134, 234, 183);
  font-size: 18px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

@media screen and (max-width: 899px) {
  .sub_green_header {
    margin-top: 10px;
    margin-bottom: 50px;
  }
}

.main_header {
  color: white;
  font-size: 40px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.downloads {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-bottom: 150px;
  margin-top: 30px;
  align-items: center;
}

.sub_header {
  color: white;
  font-size: 20px;
  margin-top: 10px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  max-width: 400px;
}

.feature_description_container {
  transform: translateX(100px);
}

.feature_mobile_image {
  width: 400px;
  transform: translateX(100px);
  transition: opacity 1s ease-in-out;
}

.feature_container {
  display: flex;
  flex-direction: row;
  width: 100vw;
  justify-content: center;
}

.sub_header_bold {
  color: rgb(134, 234, 183);
  font-size: 20px;
  margin-top: 30px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-weight: bold;
  width: 100vw;
  text-align: center;
}

@media (max-width: 768px) {
  .main_header {
    font-size: 25px;
  }
  .sub_header {
    font-size: 15px;
    width: 300px;
  }
  .lifememologo {
    width: 180px;
  }
  .feature_mobile_image {
    width: 320px;
    transform: translateX(0%);
  }
  .feature_container {
    flex-direction: column;
    align-items: center;
    width: 100vw;
    margin: auto;
  }
  .sub_green_header {
    font-weight: normal;
  }
  .carousel_container {
    height: 700px;
  }
  .sub_header_bold {
    font-size: 16px;
  }
  .downloads {
    margin-top: 50px;
    padding-bottom: 100px;
  }
  .main_header {
    color: rgb(134, 234, 183);
  }
}

.landing_demo {
  width: 100%; /* Make the video fill the container width */
  height: auto; /* Maintain aspect ratio */
}

.landing_demo_container {
  width: 340px; /* Set a maximum width for the container (600px - 30px on each side) */
  padding: 0 30px; /* Add padding */
  margin-left: 50px;
}

.landing_demo_container_mobile {
  width: 340px; /* Set a maximum width for the container (600px - 30px on each side) */
  padding: 0 30px; /* Add padding */
  margin-top: 30px;
}

@media screen and (max-width: 1099px) {
  .landing_demo_container {
    margin-left: 20px; /* Adjust margin-left for screen width less than 1100px */
  }
}

.solid_colour_container {
  background: #009fb7;
}
