.pageContainer {
  height: 100vh; /* Set the container height to the full viewport height */
  display: flex;
  width: 100%;
  flex-direction: column;
  /* Add any other styling for your content here */
}

.solid_colour_container {
  background: #009fb7;
}

.top_section {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.faded_container {
  background: linear-gradient(to bottom, #009fb7, #66a5ad);
  flex: 1;
}

.lifememo_text {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.footer_text {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: white;
}

.lifememo_error {
  color: #ff5252;
}

@media (max-width: 768px) {
  .lifememo_text {
    font-size: 13px;
  }
}

@media (max-width: 768px) {
  .footer_text {
    font-size: 12px;
  }
}
