.lifememo_text {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.prompt_text {
  color: white;
  font-weight: bold;
}

.contact_form_container {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.form_label {
  color: white;
}

.pulse_loader_container {
  margin-top: 20px;
}

.form_label_container {
  width: 200px;
  margin-top: 20px;
}

.form_input {
  width: 300px;
  margin-top: 10px;
  border-radius: 5px;
  padding: 10px;
  border: 0px solid white;
}

.submit_button {
  background-color: rgba(79, 160, 149, 1);
  color: white;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid white;
  cursor: pointer;
  width: 100px;
  font-size: 16px;
  font-weight: normal;
}

.button_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.success_message {
  color: rgb(211, 243, 211);
  font-weight: bold;
}

.error_message {
  color: rgb(250, 220, 220);
  font-weight: normal;
}

.hidden {
  display: none;
}

.loading_spinner {
  color: white;
}

@media (max-width: 768px) {
  .prompt_text {
    font-weight: normal;
    font-size: 16px;
  }
}
